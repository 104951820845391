import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Agency } from 'Assets/Icons/agency.svg';
import { ReactComponent as B2b } from 'Assets/Icons/b2b.svg';
import { ReactComponent as Brochures } from 'Assets/Icons/brochures.svg';
import { ReactComponent as Calendar } from 'Assets/Icons/calendar.svg';
import { ReactComponent as CalendarDate } from 'Assets/Icons/calendar-date.svg';
import { ReactComponent as Car } from 'Assets/Icons/car.svg';
import { ReactComponent as CarBrochure } from 'Assets/Icons/carbrochure.svg';
import { ReactComponent as Chat } from 'Assets/Icons/chat.svg';
import { ReactComponent as Check } from 'Assets/Icons/check.svg';
import { ReactComponent as Clock } from 'Assets/Icons/clock.svg';
import { ReactComponent as Close } from 'Assets/Icons/close-thin.svg';
import { ReactComponent as Company } from 'Assets/Icons/company.svg';
import { ReactComponent as Cog } from 'Assets/Icons/cog.svg';
import { ReactComponent as DealerSite } from 'Assets/Icons/dealer-site.svg';
import { ReactComponent as ErrorIcon } from 'Assets/Icons/error.svg';
import { ReactComponent as FinancingOffer } from 'Assets/Icons/financing-offer.svg';
import { ReactComponent as InboundCall } from 'Assets/Icons/inbound-calls.svg';
import { ReactComponent as Info } from 'Assets/Icons/info.svg';
import internalSettings from 'Utils/Settings/Internal';


const iconSize = size => {
  if (size === 'default') {
    return '24px';
  }
  if (size === 'xsmall') {
    return '14px';
  }
  if (size === 'small') {
    return '16px';
  }
  if (size === 'large') {
    return '32px';
  }
  if (size === 'xlarge') {
    return '50px';
  }
};

const Wrapper = styled.div`
  fill: ${props => props.fill};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: ${props => iconSize(props.size)};
    height: ${props => iconSize(props.size)};
  }
`;

const Icon = ({ size, icon, fill }) => {
  const type = {
    agency: Agency,
    b2b: B2b,
    brochures: Brochures,
    calendar: Calendar,
    calendardate: CalendarDate,
    car: Car,
    carbrochure: CarBrochure,
    chat: Chat,
    check: Check,
    clock: Clock,
    close: Close,
    cog: Cog,
    company: Company,
    dealersite: DealerSite,
    error: ErrorIcon,
    financingoffer: FinancingOffer,
    inboundcall: InboundCall,
    info: Info,
  };

  const Value = type[icon.toLowerCase()];
  return (
    <Wrapper size={size} fill={fill}>
      {Value && (
        <Value />
      )}
    </Wrapper>
  );
};

Icon.propTypes = {
  size: string,
  icon: string.isRequired,
  fill: string,
};

Icon.defaultProps = {
  size: 'default',
  fill: internalSettings.color.secondary,
};

export default Icon;
