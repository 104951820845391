import { isEmpty, isNil } from 'lodash';
import moment from 'moment-timezone';
import { keys } from 'Utils/Settings/Internal';

export const getReferrer = () => {
  const DIRECT = 'direct';
  const storedReferrer = localStorage.getItem(keys.referrerUrl);
  const storedReferrerTtl = localStorage.getItem(keys.referrerTtl);
  const referrerTtl = isNil(storedReferrerTtl) ? null : moment(storedReferrerTtl);
  const utmSource = new URL(window.location.href).searchParams.get('utm_source');
  const now = moment();

  const setReferrerData = (value) => {
    const date = moment().add(90, 'days').toISOString();

    localStorage.setItem(keys.referrerUrl, value);
    localStorage.setItem(keys.referrerTtl, date);

    if (!isNil(utmSource)) {
      localStorage.setItem(keys.utmSource, utmSource);
    }
  }

  if (!isNil(storedReferrer)) {
    if (!referrerTtl.isBefore(now)) {
      return storedReferrer;
    }

    localStorage.removeItem(keys.referrerUrl);
    localStorage.removeItem(keys.referrerTtl);
    localStorage.removeItem(keys.utmSource);
  }

  let referrer = document.referrer;

  if (isEmpty(referrer)) {
    setReferrerData(DIRECT);
    return DIRECT;
  }

  referrer = new URL(referrer);

  if (referrer.hostname === document.location.hostname) {
    setReferrerData(DIRECT);
    return DIRECT;
  }

  setReferrerData(referrer.hostname);
  return referrer;
};
