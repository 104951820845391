import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import styled from 'styled-components';
import Icon from 'Components/Icon';
import { SettingsContext } from 'Foundation/Settings';
import internalSettings from 'Utils/Settings/Internal';


const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  border-radius: ${internalSettings.radius.smaller};
  -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
  color: ${internalSettings.color.grayDarker};
  cursor: pointer;
  margin-bottom: ${internalSettings.spacing.default};
  padding: ${internalSettings.spacing.small};
  transition: all 0.3s ease-out;
  &:hover {
    -webkit-box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.15);
    box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.15);
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const Header = styled.div`
  font-size: ${internalSettings.font.size.default};
  font-weight: 600;
  margin-bottom: 2px;
  text-align: left;
`;
const Message = styled.div`
  font-size: ${internalSettings.font.size.smaller};
  font-weight: 400;
  margin: 0;
  text-align: left;
`;
const IconContainer = styled.div`
  background-color: ${internalSettings.color.grayLightest};
  border-radius: ${internalSettings.radius.smaller};
  padding: ${internalSettings.spacing.small};
`;
const ContentContainer = styled.div`
  padding-left: ${internalSettings.spacing.small};
`;

const ContentActionContainer = ({ actionType, header, icon, message, view, callback }) => {
  const { settings } = useContext(SettingsContext);
  const { t } = useTranslation();

  return (
    <ActionContainer
      onClick={() => callback({ type: view, actionName: header, actionType })}
    >
      <IconContainer>
        <Icon size="default" icon={icon} fill={settings.color.secondary} />
      </IconContainer>
      <ContentContainer>
        <Header>{t(header)}</Header>
        <Message>{t(message)}</Message>
      </ContentContainer>
    </ActionContainer>
  );
};

ContentActionContainer.propTypes = {
  actionType: string.isRequired,
  header: string,
  icon: string.isRequired,
  message: string,
  view: string.isRequired,
  callback: func.isRequired,
  type: string,
};

ContentActionContainer.defaultProps = {
  header: null,
  message: null,
  type: null
};

export default ContentActionContainer;
