
import { arrayOf, func, oneOfType, shape } from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';
import TopSection from 'Components/TopSection';
import ContentActionContainer from 'Components/ContentActionContainer';
import { SettingsContext } from 'Foundation/Settings';
import internalSettings from 'Utils/Settings/Internal';


const Wrapper = styled.div`
  background-color: transparent;
  color: ${internalSettings.color.grayDarker};
  position: relative;
`;

const DefaultView = ({ reducer: [, dispatch] }) => {
  const { settings } = useContext(SettingsContext);
  return (
    <Wrapper>
      <TopSection
        header={settings.view.bookingSelection.header}
        message={settings.view.bookingSelection.message}
      />
      <ContentActionContainer
        actionType={'scheduled'}
        callback={dispatch}
        header={settings.view.bookingSelection.schedule.header}
        icon={settings.view.bookingSelection.schedule.icon}
        message={settings.view.bookingSelection.schedule.message}
        view={'dateSelection'}
      />
      <ContentActionContainer
        actionType={'now'}
        callback={dispatch}
        header={settings.view.bookingSelection.now.header}
        icon={settings.view.bookingSelection.now.icon}
        message={settings.view.bookingSelection.now.message}
        view={'booking'}
      />
    </Wrapper>
  );
};

DefaultView.propTypes = {
  reducer: arrayOf(oneOfType([shape(), func])).isRequired,
}

export default DefaultView;
