import { node } from 'prop-types';
import React, { Suspense, useContext } from 'react';
import root from 'react-shadow/styled-components';
import styled from 'styled-components';
import Widget from 'Components/Widget';
import { SettingsContext, SettingsProvider } from 'Foundation/Settings';
import 'Assets/Styles/main.css';
import { setItem } from 'Foundation/Tracking';
import 'Foundation/Translations/I18n';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/nb';
import 'moment/locale/se';

const Wrapper = styled(root.section)`
  font-family: 'Montserrat', sans-serif;

  &* {
    font-family: 'Montserrat', sans-serif;
  }
`;

const Providers = ({ children }) => (
  <SettingsProvider>
    {children}
  </SettingsProvider>
);

Providers.propTypes = {
  children: node.isRequired,
};

const Loader = () => {
  const { loading } = useContext(SettingsContext);

  if (loading) {
    return null;
  }

  setItem(window.location.href);

  return <Widget />;
};

const App = () => (
  <Suspense fallback={null}>
    <Providers>
      <Wrapper>
        <Loader />
      </Wrapper>
    </Providers>
  </Suspense>

);

export default App;
