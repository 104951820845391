import { find, flatMap, forEach, map, uniqueId } from 'lodash';
import moment from 'moment-timezone';
import { arrayOf, func, oneOfType, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Calendar from 'Components/Calendar';
import TopSection from 'Components/TopSection';
import { SettingsContext } from 'Foundation/Settings';
import internalSettings from 'Utils/Settings/Internal';


const BookingSelection = styled.div`
  background-color: transparent;
  color: ${internalSettings.color.grayDarker};
  position: relative;
`;

const CalendarContainer = styled.div`
  margin-bottom: ${internalSettings.spacing.default};
`;

const TimeSelectors = styled.div`
  align-items: flex-start;
  background-color: ${internalSettings.color.grayLighter};
  border-radius: ${internalSettings.radius.smallest};
  border-spacing: ${internalSettings.spacing.tiny};
  box-sizing: border-box;
  color: ${internalSettings.color.grayDarker};
  display: flex;
  flex-wrap: wrap;
  font-size: 11px;
  justify-content: flex-start;
  margin: 0 0 ${internalSettings.spacing.default} 0;
  padding: ${internalSettings.spacing.smaller};
  width: 100%;
`;

const TimeSelectorWrapper = styled.div`
  box-sizing: border-box;
  width: 25%;
  padding: 2px;
`;

const TimeSelector = styled.div`
  background-color: #FFFFFF;
  border-radius: ${internalSettings.radius.smallest};
  border: 1px solid ${props => props.selected ? props.borderColor : 'transparent'};
  color: ${internalSettings.color.grayDarker};
  cursor: pointer;
  font-size: 11px;
  padding: ${internalSettings.spacing.smallest} ${internalSettings.spacing.tiny};
  text-align: center;
  &:hover {
    border-radius: ${internalSettings.radius.smallest};
    ${props => !props.selected && 'border-color:' + internalSettings.color.grayLight + ';'}
  }
  ${props => props.selected && 'font-weight: 500;'}
`;

const SelectButtonWrapper = styled.div`
  text-align: center;
`;

const SelectButton = styled.button`
  background-color: ${internalSettings.color.grayDarker};
  border-radius: ${internalSettings.spacing.default};
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  outline: none;
  border: none;
  padding: ${internalSettings.spacing.smaller} ${internalSettings.spacing.default};
  text-align: center;
  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const getTimeLabel = startMom => `${startMom.format('HH:mm')} - ${startMom.clone().add(30, 'minutes').format('HH:mm')}`;

const setupTimeSlots = (mom, openingHours) => {
  const now = moment();
  const dayObj = openingHours[moment(mom).locale('en').format('dddd').toLowerCase()];
  let arr = []; // array with time objects
  let timeDate = moment(mom).minutes(0).seconds(0).milliseconds(0);

  // is date selected today
  if (mom.date() === now.date() && mom.year() === now.year()) {
    timeDate.hours(now.hours() + 1);
  } else {
    timeDate.hours(dayObj.open);
  }

  do {
    // time object
    arr.push({
      startMom: moment(timeDate),
      label: getTimeLabel(moment(timeDate))
    });

    // add 30 minutes
    timeDate.add(30, 'minutes');
  } while (timeDate.hours() < dayObj.close)

  return arr;
}

const setSelectedTimeObj = (arr, mom) => {
  // flatmap(no copy) timeselector arrays
  let flat = flatMap(arr);

  // set selected
  forEach(flat, item => {
    if (item.disable) {
      return;
    }

    item.selected = mom.unix() === item.startMom.unix();
  });

  // find selected
  let item = find(flat, item => item.selected);

  // no selected (at date change previous selected time doesn't exist as option for this selected day)
  // set selected to first timeslot for this day
  if (!item) {
    item = flat[0];
    item.selected = true;
  }

  return item;
}

const initialState = {
  dateSelected: null,
  timeSelectors: []
}

const DateSelection = ({ reducer }) => {
  const [{ timeSelected }, dispatch] = reducer;
  const [{ dateSelected, timeSelectors }, setState] = useState(initialState);
  const { settings } = useContext(SettingsContext);
  const { t } = useTranslation();

  const calendarCallback = date => {
    let mom = moment(date);

    if (timeSelected) {
      mom.hours(timeSelected.hours()).minutes(timeSelected.minutes());
    }

    const timeSlots = setupTimeSlots(mom, settings.openingHours);
    const timeObjSelected = setSelectedTimeObj(timeSlots, mom);

    dispatch({
      type: 'dateSelectionSetTime',
      timeSelected: timeObjSelected ? timeObjSelected.startMom : null,
    });

    // update
    setState(prevState => ({
      ...prevState,
      dateSelected: timeObjSelected ? timeObjSelected.startMom : mom,
      timeSelectors: timeSlots,
    }));
  }

  useEffect(() => {
    if (!timeSelected) {
      return;
    }

    calendarCallback(timeSelected);
  },[]); // eslint-disable-line react-hooks/exhaustive-deps

  const setTimeSelected = timeObj => {
    if (timeObj.disable) {
      return;
    }
    // set selected time object
    setSelectedTimeObj(timeSelectors, timeObj.startMom);

    // update
    setState(prevState => ({
      ...prevState,
      dateSelected: timeObj.startMom,
    }));

    dispatch({ type: 'dateSelectionSetTime', timeSelected: timeObj.startMom});
  }

  return (
    <BookingSelection>
      <TopSection
        header={settings.view.dateSelection.header}
        message={settings.view.dateSelection.message}
      />
      <CalendarContainer>
        <Calendar callback={calendarCallback} dateSelected={timeSelected}></Calendar>
      </CalendarContainer>
      { dateSelected && (
        <TimeSelectors>
          {map(timeSelectors, obj => (
            <TimeSelectorWrapper key={uniqueId()}>
              <TimeSelector
                borderColor={settings.color.secondary}
                selected={obj.selected}
                onClick={() => setTimeSelected(obj)}
              >
              {obj.label}
              </TimeSelector>
            </TimeSelectorWrapper>
          ))}
        </TimeSelectors>
      )}
      <SelectButtonWrapper>
        <SelectButton
          className={[!timeSelected ? 'disabled' : '']}
          onClick={() => {
            if (!timeSelected) {
              return;
            }
            dispatch({ type: 'booking' });
          }}
        >
          {t('widget:dateSelection.labels.SelectTime')}
        </SelectButton>
      </SelectButtonWrapper>
    </BookingSelection>
  );
}

DateSelection.propTypes = {
  reducer: arrayOf(oneOfType([shape(), func])).isRequired,
};

export default DateSelection;