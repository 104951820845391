const internalSettings = {
  background: '#FFFFFF',
  width: 380,
  apiUrl: 'https://api.easir.com',
  color: {
    error: '#cf3535',
    primary: '#00a76d',
    grayLightest: '#f5f5f5',
    grayLighter: '#f2f2f2',
    grayLight: '#cccccc',
    gray: '#a1a2a1',
    grayDark: '#999999',
    grayDarker: '#555555',
  },
  font: {
    size: {
      default: '14px',
      large: '16px',
      larger: '18px',
      largest: '22px',
      small: '13px',
      smaller: '12px',
    },
  },
  radius: {
    default: '16px',
    smaller: '8px',
    smallest: '4px',
  },
  spacing: {
    default: '16px',
    large: '20px',
    larger: '24px',
    largest: '32px',
    small: '12px',
    smaller: '8px',
    smallest: '4px',
    tiny: '2px',
  },
};

export default internalSettings;

export const keys = {
  trackingData: 'easir-url-tracking',
  referrerUrl: 'easir-referrer-url',
  utmSource: 'easir-utm-source',
  referrerTtl: 'easir-referrer-ttl',
};
