import { Formik } from 'formik';
import { isEmpty, isNil, map } from 'lodash';
import { arrayOf, func, oneOfType, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import styled from 'styled-components';
import TopSection from 'Components/TopSection';
import fetch from 'Foundation/Fetch';
import { SettingsContext } from 'Foundation/Settings';
import { getTrackingValues } from 'Foundation/Tracking';
import internalSettings, { keys } from 'Utils/Settings/Internal';

const ViewBooking = styled.div`
  background-color: transparent;
  color: ${internalSettings.color.grayDarker};
  position: relative;
`;

// SUMMARY
const Summary = styled.div`
  background-color: ${internalSettings.color.grayLighter};
  border-radius: ${internalSettings.radius.smallest};
  color: ${internalSettings.color.grayDarker};
  margin-bottom: ${internalSettings.spacing.default};
  padding: ${internalSettings.spacing.small};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ali
`;

const SummaryText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${internalSettings.spacing.smallest};
`;

const SummaryHeader = styled.div`
  font-size: ${internalSettings.font.size.default};
  font-weight: 600;
`;

const SummaryMessage = styled.div`
  font-size: ${internalSettings.font.size.small};
  font-weight: normal;
`;

const SummaryEdit = styled.div`
  cursor: pointer;
  font-size: ${internalSettings.font.size.default};
  font-weight: normal;
  &:hover {
    text-decoration: underline;
  }
`;

// FORM
const Input = styled.input`
  border-radius: ${internalSettings.radius.smallest};
  border: 1px solid ${internalSettings.color.grayLight};
  box-sizing: border-box;
  display: block;
  font: inherit;
  margin: 0 0 ${internalSettings.spacing.tiny} 0;
  padding: ${internalSettings.spacing.smaller} ${internalSettings.spacing.smaller};
  width: 100%;
  &.error {
    border-color: ${internalSettings.color.error};
  }
`;
const FormTextarea = styled.textarea`
  border: 1px solid ${internalSettings.color.grayLight};
  border-radius: ${internalSettings.radius.smallest};
  box-sizing: border-box;
  font: inherit;
  resize: none;
  width: 100%;
  height: 60px;
  padding: ${internalSettings.spacing.small} ${internalSettings.spacing.smaller};
`;
const InputWrapper = styled.div`
  margin-bottom: ${internalSettings.spacing.small};
  width: 100%;
`;
const InputError = styled.div`
  font-size: ${internalSettings.font.size.smaller};
`;

// CONSENT
const Consent = styled.div`
  background-color: ${internalSettings.color.grayLighter};
  border: 1px solid transparent;
  border-radius: ${internalSettings.radius.smallest};
  color: ${internalSettings.color.grayDarker};
  margin-bottom: ${internalSettings.spacing.default};
  padding: ${internalSettings.spacing.small};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  &.error {
    border: 1px solid ${internalSettings.color.error};
  }
`;
const InputCheckbox = styled.input`
  cursor: pointer;
  height: 16px;
  margin: 0 ${internalSettings.spacing.small} 0 0;
  width: 16px;
`;
const CheckboxText = styled.label`
  font-size: ${internalSettings.font.size.smaller};
  cursor: pointer;
`;

// SUBMIT
const SubmitWrapper = styled.div`
  text-align: center;
`;
const Submit = styled.button`
  background-color: ${internalSettings.color.grayDarker};
  border-radius: ${internalSettings.spacing.default};
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  outline: none;
  border: none;
  padding: ${internalSettings.spacing.smaller} ${internalSettings.spacing.default};
  text-align: center;
`;

const Booking = ({ reducer }) => {
  const { t } = useTranslation();
  const { settings } = useContext(SettingsContext);
  const [
    {
      leadType,
      leadTypeName,
      timeSelected,
    },
    dispatch,
  ] = reducer;

  const getTimeSelectedLabel = () => {
    const date = timeSelected;
    const addHalfHour = date.clone().add(30, 'minutes');

    return `${date.format('dddd, MMMM Do (HH:mm')} - ${addHalfHour.format('HH:mm')})`;
  };

  return (
    <ViewBooking>
      <TopSection
        header={settings.view.booking.header}
        message={settings.view.booking.message}
      ></TopSection>
      <Summary>
        <SummaryHeader>{leadTypeName}</SummaryHeader>
        {timeSelected && (
          <SummaryText>
              <SummaryMessage>{getTimeSelectedLabel()}</SummaryMessage>
              <SummaryEdit
                onClick={() => dispatch({ type: 'dateSelection' })}
              >
                {t('widget:booking.labels.edit')}
              </SummaryEdit>
          </SummaryText>
        )}
      </Summary>
      <Formik
        initialValues={{
          accountName: '',
          b2c: 'true',
          consent: false,
          email: '',
          firstName: '',
          lastName: '',
          message: '',
          phone: '',
          team: '',
        }}
        validate={values => {
          const errors = {};

          if (!values.accountName && values.b2c === 'false') {
            errors.accountName = 'Field required';
          }

          if (!values.firstName) {
            errors.firstName = 'Field required';
          }

          if (!values.lastName) {
            errors.lastName = 'Field required';
          }

          if (!values.email) {
            errors.email = 'Field required';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
          }

          if (!values.team && !isEmpty(settings.teams) && settings.teams.length > 1) {
            errors.team = true;
          }

          return errors;
        }}
        onSubmit={async ({
          accountName,
          b2c,
          consent,
          email,
          firstName,
          lastName,
          message,
          phone,
          team,
        }, { setSubmitting }) => {
          let referrer = localStorage.getItem(keys.referrerUrl);
          const utmSource = localStorage.getItem(keys.utmSource);
          const savedPages = getTrackingValues();

          if (!isNil(utmSource)) {
            referrer = `${referrer} - ${utmSource}`;
          }

          let query = {
            b2c: b2c === 'true',
            contact: {
              fixed_fields: [{
                  name: 'first_name',
                  value: firstName,
                },
                {
                  name: 'last_name',
                  value: lastName,
                },
                {
                  name: 'email',
                  value: email,
                },
                {
                  name: 'mobile_phone_number',
                  value: phone,
                },
              ],
            },
            lead_source: isNil(referrer) ? settings.name : referrer,
            interest: document.title,
            lead_data: [
              {
                key: 'Url',
                value: document.URL,
              },
            ],
            lead_type_id: leadType,
            token: settings.token,
          };

          if (consent) {
            query.contact = {
              ...query.contact,
              consent: {
                email: {
                  consent_given: true
                },
                call: {
                  consent_given: true,
                },
              },
            };
          }

          if (message) {
            query.lead_data.push({
              key: t('widget:leadData.message'),
              value: message,
            });
          }

          if (settings.teams && settings.teams.length === 1) {
            query.team_id = settings.teams[0].id;
          } else if (team) {
            query.team_id = team;
          }

          if (timeSelected) {
            query.lead_data.push({
              key: t('widget:leadData.time'),
              value: timeSelected.format('dddd, MMMM Do HH:mm'),
            });
          }

          if (!isEmpty(savedPages)) {
            query.lead_data.push(
              {
                type: 'heading',
                value: t('widget:booking.recentlyViewed'),
              },
              {
                type: 'table',
                value: [
                  ['URL', t('widget:leadData.date')],
                  ...savedPages,
                ],
              },
            );
          }

          if (query.b2c) {
            query = {
              ...query,
              account_id: settings.accountId,
            };
          } else {
            query = {
              ...query,
              account: {
                fixed_fields: [{
                  name: 'name',
                  value: accountName,
                }],
              },
            };
          }

          try {
            await fetch(`${internalSettings.apiUrl}/external/leads`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(query)
            });

            // fire event
            const eventDetails = Object.assign({}, query);
            eventDetails.form = leadTypeName;
            delete eventDetails.token;
  
            const widget = document.getElementById('widget');
            widget.dispatchEvent(new CustomEvent('widgetLeadCreated', {
              bubbles: true,
              detail: eventDetails
            }));

            setSubmitting(false);
            dispatch({type: 'confirmation'});
          } catch (err) {
            setSubmitting(false);
            // handle error
          }

        }}
      >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          {!isEmpty(settings.teams) && settings.teams.length > 1 && (
            <InputWrapper>
              <Input
                className={[touched.team && errors.team ? 'error' : '']}
                as="select"
                name="team"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.team}
              >
                <option value="">{settings.teamsPlaceholder}</option>
                {map(settings.teams, ({ id, name }) => (
                    <option key={id} value={id}>{name}</option>
                ))}
              </Input>
              <InputError>{errors.team && touched.team && errors.team}</InputError>
            </InputWrapper>
          )}
          <InputWrapper>
            <Input
              as="select"
              name="b2c"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.b2c}
            >
              <option value="true">{t('widget:booking.labels.private')}</option>
              <option value="false">{t('widget:booking.labels.company')}</option>
            </Input>
            <InputError>{errors.b2c && touched.b2c && errors.b2c}</InputError>
          </InputWrapper>
          {values.b2c === 'false' && (
            <InputWrapper>
              <Input
                className={[touched.accountName && errors.accountName ? 'error' : '']}
                name="accountName"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={t('widget:booking.labels.accountName')}
                type="text"
                value={values.accountName}
              />
            </InputWrapper>
          )}
          <InputWrapper>
            <Input
              className={[touched.firstName && errors.firstName ? 'error' : '']}
              name="firstName"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={t('widget:booking.labels.firstName')}
              type="text"
              value={values.firstName}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              className={[touched.lastName && errors.lastName ? 'error' : '']}
              name="lastName"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={t('widget:booking.labels.lastName')}
              type="text"
              value={values.lastName}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              name="phone"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={t('widget:booking.labels.telephone')}
              type="tel"
              value={values.phone}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              className={[touched.email && errors.email ? 'error' : '']}
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={t('widget:booking.labels.email')}
              type="email"
              value={values.email}
            />
          </InputWrapper>
          <InputWrapper>
            <FormTextarea
              name="message"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={t('widget:booking.labels.additionalMessage')}
              type="text"
              value={values.message}
            />
          </InputWrapper>
          {settings.view.booking.consent && (
            <Consent className={[touched.consent && errors.consent ? 'error' : '']}>
              <InputCheckbox
                id="consent"
                name="consent"
                onBlur={handleBlur}
                onChange={handleChange}
                type="checkbox"
                value={values.consent}
              />
              <CheckboxText htmlFor="consent">{t(settings.view.booking.consentText)}</CheckboxText>
            </Consent>
          )}
          <SubmitWrapper>
            <Submit type="submit" disabled={isSubmitting}>
              {t('widget:booking.labels.sendRequest')}
            </Submit>
          </SubmitWrapper>
        </form>
      )}
      </Formik>
    </ViewBooking>
  );
}

Booking.propTypes = {
  reducer: arrayOf(oneOfType([shape(), func])).isRequired,
};

export default Booking;
