import { isEmpty, isNil, map, slice } from 'lodash';
import moment from 'moment-timezone';
import { keys } from 'Utils/Settings/Internal';

const getItems = () => {
  const data = localStorage.getItem(keys.trackingData);

  if (isNil(data)) {
    return [];
  }

  return JSON.parse(data);
};

export const setItem = (url) => {
  const removeLastItem = (data) => {
    if (isEmpty(data) || data.length < 10) {
      return data;
    }
  
    return slice(data, 1);
  };

  const items = removeLastItem(getItems());
  const date = moment().format('dddd, MMMM Do HH:mm');

  items.push({ url, date })
  localStorage.setItem(keys.trackingData, JSON.stringify(items));
};

export const getTrackingValues = () => {
  const items = getItems();

  if (isEmpty(items)) {
    return [];
  }

  return map(items, ({ url, date }) => [url, date]);
};
