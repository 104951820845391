import React, { useContext, useReducer } from 'react';
import styled from 'styled-components';
import MainButton from 'Components/MainButton';
import MainHeader from 'Components/MainHeader';
import Views from 'Components/Views';
import { SettingsContext } from 'Foundation/Settings';
import internalSettings from 'Utils/Settings/Internal';

const initialState = {
  active: false,
};

const WidgetWrapper = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 9999999999;
  ${props => props.placement.left && 'left: 0;'}
  ${props => !props.placement.left && 'right: 0;'}

  @media (max-width: 768px) {
    top: 0;
    width: 100%;
    bottom: auto;
  }
`;

const Wrapper = styled.div`
  background-color: ${internalSettings.background};
  border-radius: ${internalSettings.radius.default};
  border: 1px solid ${props => props.borderColor};
  box-shadow: 2px 2px 16px 8px rgba(0,0,0,0.10);
  font-weight: 400;
  font-size: ${internalSettings.font.size.default};
  position: fixed;
  width: ${internalSettings.width + 'px'};
  bottom: 94px;
  ${props => props.placement.left && 'left: 20px;'}
  ${props => !props.placement.left && 'right: 20px;'}

  @media (max-width: 768px) {
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    border-radius: 0;
    padding-top: 40px;
    border: none;
    ${props => props.placement.left && 'left: 0;'}
    ${props => !props.placement.left && 'right: 0;'}
  }
`;

const openReducer = (state, action) => {
  if (action.type === state.view) {
    return state;
  }
  switch (action.type) {
    case 'toggleWidget':
      return {
        ...state,
        leadType: null,
        leadTypeName: null,
        timeSelected: null,
        active: !state.active,
        view: 'introduction'
      }
    case 'introduction':
      return {
        ...state,
        leadType: null,
        leadTypeName: null,
        timeSelected: null,
        view: 'introduction'
      }
    case 'bookingSelection':
      return {
        ...state,
        leadType: action.actionType ? action.actionType : null,
        leadTypeName: action.actionName ? action.actionName : null,
        view: 'bookingSelection'
      }
    case 'dateSelection':
      return {
        ...state,
        leadType: state.view === 'introduction' ? action.actionType : state.leadType,
        leadTypeName: state.view === 'introduction' ? action.actionName : state.leadTypeName,
        view: 'dateSelection'
      }
    case 'dateSelectionSetTime':
      return {
        ...state,
        timeSelected: action.timeSelected,
      }
    case 'booking':
      return {
        ...state,
        view: 'booking',
        leadType: state.view === 'introduction' ? action.actionType : state.leadType,
        leadTypeName: state.view === 'introduction' ? action.actionName : state.leadTypeName,
      }
    case 'bookNow':
      return {
        ...state,
        view: 'bookNow'
      }
    case 'confirmation':
      return {
        ...state,
        view: 'confirmation'
      }
    default:
      return state;
  }
}

const Widget = () => {
  const reducer = useReducer(openReducer, initialState);
  const [{ active }] = reducer;
  const { settings } = useContext(SettingsContext);

  return (
    <WidgetWrapper placement={settings.placement}>
      <MainButton reducer={reducer} />
      { active && (
        <Wrapper
          placement={settings.placement}
          spacing={(settings.mainButton.size + settings.mainButton.marginToView) + 'px'}
          borderColor={settings.color.primary}
        >
          <MainHeader reducer={reducer}></MainHeader>
          <Views reducer={reducer}></Views>
        </Wrapper>
      ) }
    </WidgetWrapper>
  );
};

export default Widget;
