import { arrayOf, func, oneOfType, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import styled from 'styled-components';
import Icon from 'Components/Icon';
import TopSection from 'Components/TopSection';
import { SettingsContext } from 'Foundation/Settings';
import internalSettings from 'Utils/Settings/Internal';


const Wrapper = styled.div`
  background-color: transparent;
  color: ${internalSettings.color.grayDarker};
  position: relative;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Success = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid primary;
  background-color: transparent;
  margin: 16px 0 32px 0;
`;

const BookingInfo = styled.div`
  padding: 12px 16px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  margin-bottom: 16px;
`;

const BookingType = styled.div`
  font-weight: 600;
  margin: 4px 0;
`;

const BookingTime = styled.div`
  margin: 4px 0;
`;

const BottomMessage = styled.div`
  padding: 20px 16px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  background-color: ${internalSettings.color.grayLighter};
  margin-bottom: 20px;
`;

const Logo = styled.img`
  max-width: 140px;
  max-height: 80px;
  margin: 32px 0;
`;

const getBookingTime = date => {
  const addHalfHour = date.clone().add(30, 'minutes');

  return `${date.format('dddd, MMMM Do (HH:mm')} - ${addHalfHour.format('HH:mm')})`;
};

const ConfirmationView = ({
  reducer: [{ leadTypeName, timeSelected }],
}) => {
  const { settings } = useContext(SettingsContext);
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Success>
        <Icon icon="check" size="xlarge" fill={settings.color.secondary}></Icon>
      </Success>
      <TopSection
        header={settings.view.confirmation.header}
        message={settings.view.confirmation.message}
      />
      <BookingInfo>
        <BookingType>{leadTypeName}</BookingType>
        {timeSelected && (
          <BookingTime>{getBookingTime(timeSelected)}</BookingTime>
        )}
      </BookingInfo>
      <BottomMessage>{t(settings.view.confirmation.bottomMessage)}</BottomMessage>
      <Logo src={settings.logo} />
    </Wrapper>
  );
};

ConfirmationView.propTypes = {
  reducer: arrayOf(oneOfType([shape(), func])).isRequired,
}

export default ConfirmationView;
