import { arrayOf, func, oneOfType, shape } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import DateAndTime from 'Components/Views/DateAndTime';
import Booking from 'Components/Views/Booking';
import BookingSelection from 'Components/Views/BookingSelection';
import Confirmation from 'Components/Views/Confirmation';
import Introduction from 'Components/Views/Introduction';
import internalSettings from 'Utils/Settings/Internal';

const Wrapper = styled.div`
  background-color: transparent;
  color: ${internalSettings.color.grayDarker};
  padding: ${internalSettings.spacing.large} ${internalSettings.spacing.default}
    60px ${internalSettings.spacing.default};
  position: relative;

  @media (min-width: 768px) {
    padding-bottom: ${internalSettings.spacing.large};
  }

  @media (min-width: 768px) and (max-height: 800px) {
    max-height: 450px;
    overflow: auto;
  }
`;

const ViewContent = ({ reducer }) => {
  const [{ view }] = reducer;

  return (
    <Wrapper>
      {view === 'introduction' && (
        <Introduction
          reducer={reducer}
        />
      )}
      {view === 'bookingSelection' && (
        <BookingSelection
          reducer={reducer}
        />
      )}
      {view === 'dateSelection' && (
        <DateAndTime reducer={reducer} />
      )}
      {view === 'booking' && (
        <Booking reducer={reducer} />
      )}
      {view === 'confirmation' && (
        <Confirmation reducer={reducer} />
      )}
    </Wrapper>
  );
};

ViewContent.propTypes = {
  reducer: arrayOf(oneOfType([shape(), func])).isRequired,
}

export default ViewContent;
