import { string } from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { SettingsContext } from 'Foundation/Settings';
import internalSettings from 'Utils/Settings/Internal';
import { useTranslation } from 'react-i18next';

const ContentTopSection = styled.div`
  background-color: transparent;
  color: ${internalSettings.color.grayDarker};
  margin-bottom: ${internalSettings.spacing.larger};
  position: relative;
`;
const Header = styled.div`
  font-size: ${internalSettings.font.size.large};
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: ${internalSettings.spacing.default};
`;
const Message = styled.div`
  font-size: ${internalSettings.font.size.default};
  font-weight: 400;
  text-align: center;
`;
const Divider = styled.hr`
  border-top: 1px solid ${props => props.color};
  margin-bottom: ${internalSettings.spacing.default};
  text-align: center;
  width: 60px;
`;

const TopSection = ({ header, message }) => {
  const { settings } = useContext(SettingsContext);
  const { t } = useTranslation();
  return (
    <ContentTopSection>
      <Header>{t(header)}</Header>
      <Divider color={settings.color.secondary} />
      <Message>
        {t(message)}
      </Message>
    </ContentTopSection>
  );
};

TopSection.propTypes = {
  header: string,
  message: string,
};

TopSection.defaultProps = {
  header: '',
  message: '',
};

export default TopSection;
