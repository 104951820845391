import { func, shape } from 'prop-types';
import DayPicker from 'react-day-picker';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import MomentLocaleUtils from 'react-day-picker/moment';
import { SettingsContext } from 'Foundation/Settings';
import internalSettings from 'Utils/Settings/Internal';

const getToday = () => {
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  return today;
};

const StyledDayPicker = styled(DayPicker)`
  display: inline-block;
  font-size: ${internalSettings.font.size.default};
  width: 100%;

  .DayPicker-wrapper {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    border-radius: ${internalSettings.radius.smallest} ${internalSettings.radius.smallest} 0 0;
    border: 1px solid ${internalSettings.color.grayDarker};
    flex-direction: row;
    outline: none;
    padding: 0;
    position: relative;
    user-select: none;

    .DayPicker-Months {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .DayPicker-Month {
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        border-collapse: inherit;
        border-spacing: 0;
        display: table;
        padding-bottom: ${internalSettings.spacing.smaller};
        user-select: none;
        width: 100%;

        .DayPicker-Caption {
          background-color: ${internalSettings.color.grayDarker};
          color: #FFFFFF;
          display: table-caption;
          font-size: ${internalSettings.font.size.large};
          font-weight: normal;
          margin-bottom: ${internalSettings.spacing.smaller};
          padding: ${internalSettings.spacing.smaller};
          text-align: center;
        };

        .DayPicker-Weekdays {
          display: table-header-group;

          .DayPicker-WeekdaysRow {
            display: table-row;

            .DayPicker-Weekday {
              color: ${internalSettings.color.grayDark};
              display: table-cell;
              font-size: ${internalSettings.font.size.smaller};
              margin: ${internalSettings.spacing.smallest};
              padding: ${internalSettings.spacing.smallest};
              text-align: center;
            };

            .DayPicker-Weekday abbr[title] {
              text-decoration: none;
            };
          };
        }

        .DayPicker-Body {
          display: table-row-group;

          .DayPicker-Week {
            display: table-row;

            .DayPicker-Day {
              border: 1px solid transparent;
              cursor: pointer;
              display: table-cell;
              margin: ${internalSettings.spacing.smallest};
              outline: none;
              padding: 2px;
              text-align: center;
              vertical-align: middle;
              width: 28px;
              &:hover {
                font-weight: 600;
              }
            };

            .DayPicker-Day--selected {
              &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
                border: 1px solid ${props => props.borderColorSelected};
                background-color: ${internalSettings.color.grayLightest};
                border-radius: ${internalSettings.radius.smallest};
                color: ${internalSettings.color.grayDarker};
                font-weight: 800;
                outline: none;
              }
            };

            .DayPicker-Day--today {
              color: inherit;
            };

            .DayPicker-Day--outside {
              color: ${internalSettings.color.grayLight};
              cursor: not-allowed;
            }

            .DayPicker-Day--disabled {
              color: ${internalSettings.color.grayLight};
              cursor: not-allowed;
            };
          };
        };
      };
    };

    .DayPicker-NavBar {
      .DayPicker-NavButton {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 50%;
        color: ${internalSettings.color.grayLight};
        cursor: pointer;
        display: inline-block;
        height: 16px;
        left: auto;
        outline: none;
        position: absolute;
        right: 16px;
        top: ${internalSettings.spacing.smaller};
        width: 16px;
        &:hover {
          opacity: 0.8;
        }
      };

      .DayPicker-NavButton--prev {
        left: ${internalSettings.spacing.smaller};
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
      };

      .DayPicker-NavButton--next {
        right: ${internalSettings.spacing.smaller};
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
      };

      .DayPicker-NavButton--interactionDisabled {
        display: none;
      };
    }

    // if weeknumbers are displayed
    .DayPicker-WeekNumber {
      display: table-cell;
      padding: 0.5em;
      min-width: 1em;
      border-right: 1px solid #EAECEC;
      color: #8B9898;
      vertical-align: middle;
      text-align: right;
      font-size: 0.75em;
      cursor: pointer;
    }

    .DayPicker--interactionDisabled .DayPicker-Day {
      cursor: default;
    }

    // if a today button is displayed
    .DayPicker-TodayButton {
      background-color: transparent;
      background-image: none;
      border: none;
      box-shadow: none;
      color: #4A90E2;
      cursor: pointer;
      font-size: 0.875em;
    }

    .DayPicker-Footer {
      padding-top: 0.5em;
    }
  };
`;


const Calendar = ({ callback, dateSelected }) => {
  const [selectedDay, setSelectedDay] = useState(null);
  const { settings } = useContext(SettingsContext);
  const today = getToday();

  const isValid = date => !isDisabled(date) && (!selectedDay || (selectedDay && date.getTime() !== selectedDay.getTime()));
  const isDisabled = date => date.getTime() < today.getTime() || isClosedForToday(date);

  const selectDay = date => {
    if (!isValid(date)) {
      return;
    }
    setSelectedDay(date);
    callback(date);
  };

  const isClosedForToday = date => {
    const now = moment();
    const momentDate = moment(date).locale('en');
    const dayNameEng = momentDate.format('dddd').toLowerCase();
    const dayObj = settings.openingHours[dayNameEng];
    return dayObj.closed || (now.isSame(momentDate, 'date') && now.hours() + 1 >= dayObj.close);
  }

  //  set date on edit
  useEffect(() => {
    if (!dateSelected) {
      return;
    }
    selectDay(dateSelected.toDate());
  },[]); // eslint-disable-line react-hooks/exhaustive-deps

  const modifiers = {
    before: new Date(),
    disabled: isDisabled,
  };

  return (
    <StyledDayPicker
      borderColorSelected={settings.color.secondary}
      firstDayOfWeek={1}
      fromMonth={new Date()}
      locale={settings.language}
      localeUtils={MomentLocaleUtils}
      modifiers={modifiers}
      onDayClick={selectDay}
      selectedDays={selectedDay}
    />
  );
};

Calendar.propTypes = {
  callback: func.isRequired,
  dateSelected: shape(),
};

Calendar.defaultProps = {
  dateSelected: null,
}

export default Calendar;
