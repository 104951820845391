import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import * as serviceWorker from './serviceWorker';
import { getReferrer } from 'Foundation/Helpers';

// Insert element to load the app in the end of body
const ele = document.createElement('DIV');
ele.id = 'widget';
document.body.appendChild(ele);
getReferrer();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('widget')
);

// for testing event listener when lead is created (can be window., document. or widget. as here)
// const widget = document.getElementById('widget');
// widget.addEventListener('widgetLeadCreated', (e) => {
//   console.log('widget', e);
// });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
