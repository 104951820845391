const settings = {
  name: 'Website',
  accountId: '',
  token: '',
  language: 'en',
  logo: 'https://easir.com/wp-content/uploads/2019/07/EASIR-Now.svg',
  openingHours: {
    default: {
      close: 20,
      open: 7,
    },
    monday: {
      close: 17,
      open: 10,
    },
    tuesday: {
      close: 20,
      open: 7,
    },
    wednesday: {
      close: 20,
      closed: false,
      open: 7,
    },
    thursday: {
      close: 18,
      open: 12,
    },
    friday: {
      close: 18,
      open: 7,
    },
    saturday: {
      close: 20,
      closed: true,
      open: 7,
    },
    sunday: {
      close: 20,
      open: 7,
    },
  },
  placement: {
    left: false,
  },
  color: {
    primary: '#333333',
    primaryText: '#ffffff',
    secondary: '#00a76d',
  },
  mainButton: {
    text: 'widget:mainButtonHover',
    icon: 'car',
  },
  topHeader: {
    header: 'widget:headline',
  },
  actions: [],
  view: {
    introduction: {
      header: 'widget:introduction.headline',
      message: 'widget:introduction.message',
    },
    bookingSelection: {
      header: 'widget:bookingSelection.headline',
      message: 'widget:bookingSelection.message',
      now: {
        header: 'widget:bookingSelection.now.header',
        icon: 'clock',
        message: 'widget:bookingSelection.now.message',
      },
      schedule: {
        header: 'widget:bookingSelection.schedule.header',
        icon: 'calendar',
        message: 'widget:bookingSelection.schedule.message',
      },
    },
    dateSelection: {
      header: 'widget:dateSelection.headline',
      message: 'widget:dateSelection.message',
    },
    booking: {
      header: 'widget:booking.headline',
      message: '',
      consent: false,
      consentText: 'widget:booking.consent',
    },
    confirmation: {
      header: 'widget:confirmation.header',
      message: 'widget:confirmation.message',
      bottomMessage: 'widget:confirmation.bottomMessage',
    },
  },
};

export default settings;
