import { merge, set } from 'lodash';
import moment from 'moment-timezone';
import i18n from 'i18next';
import React, { createContext, useEffect, useState } from 'react';
import settings from 'Utils/Settings';

const defaultState = {
  loading: true,
  settings: [],
};

const supportedLocales = {
  da: 'da',
  de: 'de',
  en: 'en-gb',
  no: 'nb',
  se: 'se',
};

export const SettingsContext = createContext(null);
export const SettingsConsumer = SettingsContext.Consumer;

export const SettingsProvider = ({ children }) => {
  const [{ loading }, setState] = useState(defaultState);

  useEffect(() => {
    let isMounted = true;

    const isIE = /MSIE|Trident/.test(window.navigator.userAgent);

    if ( isIE ) {
      return;
    }

    // get settings id
    const ele = document.getElementById('easir-widget');
    const eleId = ele && ele.getAttribute('easir-id');
    const elePath = ele && ele.getAttribute('easir-path');
    const alternativeId = ele && ele.getAttribute('data-easir-id');
    const settingsId = eleId || alternativeId || 'test';
    const settingsPath = elePath || 'https://widget.easir.com/settings/';

    const fetchSettings = async () => {
      try {
        if (!isMounted) {
          return;
        }

        const response = await fetch(`${settingsPath}${settingsId}.json`, { cache: "no-cache" });
        const data = await response.json();
        set(settings, 'token', settingsId)
        const merged = merge(settings, data);

        if (merged.language) {
          i18n.changeLanguage(merged.language);
          moment.locale(supportedLocales[merged.language]);
        }

        setState(prevState => ({
          ...prevState,
          loading: false,
          settings: merged,
        }));
      } catch (err) {
        setState(defaultState);
      }
    };

    fetchSettings();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        loading,
        settings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
