import { arrayOf, func, oneOfType, shape } from 'prop-types';
import React, { useContext} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Icon from 'Components/Icon';
import { SettingsContext } from 'Foundation/Settings';

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  ${props => props.placement.left && 'left: 20px;'}
  ${props => !props.placement.left && 'right: 20px;'}

`;

const HelpText = styled.div`
  display: ${props => props.active ? 'none' : 'block'};
  position: fixed;
  bottom: 70px;
  ${props => props.placement.left && 'left: 0;'}
  ${props => !props.placement.left && 'right: 0;'}
  height: 32px;
  background-color: #fff;
  border: 1px solid #eee;
  line-height: 32px;
  padding: 0 16px;
  border-radius: 16px;
  color: #555;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
`;

const Button = styled.button`
  background-color: ${props => props.background};
  border-radius: 50%;
  border: 0;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.color};
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  height: 60px;
  width: 60px;
  outline: 0;
  position: relative;
  transition: all 0.2s ease-out;
  transform: ${props => props.active ? 'scale(1.1, 1.1)' : 'scale(1.0, 1.0)'};

  &:hover {
    transform: scale(1.1, 1.1);
  }
`;

const CustomIcon = styled.img`
  width: 40px;
  height: 40px;
  display: block;
`;

const MainButton = ({ reducer }) => {
  const [{ active }, dispatch] = reducer;
  const { t } = useTranslation();
  const { settings } = useContext(SettingsContext);

  return (
    <ButtonWrapper placement={settings.placement}>
      <Button
        background={settings.color.primary}
        color={settings.color.primaryText}
        onClick={() => dispatch({ type: 'toggleWidget' })}
        active={active}
      >
        {
          active ?
          <Icon icon="close" size="xsmall" fill={settings.color.primaryText} /> :
          settings.mainButton.iconUrl ?
          <CustomIcon alt="icon" src={settings.mainButton.iconUrl} /> :
          <Icon icon={settings.mainButton.icon} size="large" fill={settings.color.primaryText} />
        }
        <HelpText placement={settings.placement} active={active}>
          {t(settings.mainButton.text)}
        </HelpText>
      </Button>
    </ButtonWrapper>
  );
}

MainButton.propTypes = {
  reducer: arrayOf(oneOfType([shape(), func])).isRequired,
}

export default MainButton;