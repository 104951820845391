
import { arrayOf, func, oneOfType, shape } from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { map, uniqueId } from 'lodash';
import TopSection from 'Components/TopSection';
import ContentActionContainer from 'Components/ContentActionContainer';
import { SettingsContext } from 'Foundation/Settings';
import internalSettings from 'Utils/Settings/Internal';


const Wrapper = styled.div`
  background-color: transparent;
  color: ${internalSettings.color.grayDarker};
  position: relative;
`;

const getView = {
  timeRequired: 'dateSelection',
  noTime: 'booking',
};

const DefaultView = ({
  reducer: [, dispatch],
}) => {
  const { settings } = useContext(SettingsContext);

  return (
    <Wrapper>
      <TopSection
        header={settings.view.introduction.header}
        message={settings.view.introduction.message}
      />
      {map(settings.actions, ({ leadType, header, icon, message, timeOption }) => (
        <ContentActionContainer
          actionType={leadType}
          callback={dispatch}
          header={header}
          icon={icon}
          key={uniqueId()}
          message={message}
          view={!timeOption ? 'bookingSelection' : getView[timeOption]}
        />
      ))}
    </Wrapper>
  );
};

DefaultView.propTypes = {
  reducer: arrayOf(oneOfType([shape(), func])).isRequired,
}

export default DefaultView;
