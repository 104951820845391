import { get, set } from 'lodash';

const checkResponseStatus = async (response) => {
  const data = await response.json();

  const { status } = response;
  if (status >= 200 && status < 300) {
    return data;
  }

  const error = new Error(
    get(data, 'errors.0.message', `An error has occured while fetching (Response code: ${status})`)
  );
  set(error, 'response.data', data);
  return Promise.reject(error);
};

const request = async (path, options = {}) => checkResponseStatus(await fetch(path, options));

export default request;
