import { arrayOf, func, oneOfType, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import styled from 'styled-components';
import Icon from 'Components/Icon';
import { SettingsContext } from 'Foundation/Settings';
import internalSettings from 'Utils/Settings/Internal';

const HeaderWrapper = styled.div`
  background-color: ${props => props.background};
  border-radius: ${internalSettings.spacing.small} ${internalSettings.spacing.small} 0 0;
  color: ${props => props.color};
  position: relative;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    border-radius: 0;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 10;
  }
`;
const Header = styled.h2`
  cursor: pointer;
  margin: 0;
  flex: 1;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  width: 40px;
  height: 40px;
`;

const Button = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const MainHeader = ({ reducer: [{ view }, dispatch] }) => {
  const { settings } = useContext(SettingsContext);
  const { t } = useTranslation();

  return (
    <HeaderWrapper background={settings.color.primary} color={settings.color.primaryText}>
      <ButtonWrapper></ButtonWrapper>
      <Header onClick={() => dispatch({ type: 'introduction' })}>
        {t(settings.topHeader.header)}
      </Header>
      <ButtonWrapper>
        <Button onClick={() => dispatch({ type: 'toggleWidget' })}>
          <Icon icon="close" size="xsmall" fill={settings.color.primaryText} />
        </Button>
      </ButtonWrapper>
    </HeaderWrapper>
  );
};

MainHeader.propTypes = {
  reducer: arrayOf(oneOfType([shape(), func])).isRequired,
}

export default MainHeader;
